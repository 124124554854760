import * as MapApi from '@/api/mapApi';
import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@ionic/storage';

const tempMarkerTemplate = {
    latLng: null,
    category: null,
    title: null,
    location: null,
    description: null,
    languages: [],
    audio: {
        base64: null,
        blob: null,
        duration: 0
    },
    photos: [],
    screenOrienrtationEvent: null,
    deviceOrientationEvent: null,
    isInARMode: false
};

const freshState = () => ({
    currentLocation: null,
    initGoogleMap: false,
    markers: [],
    markerSearchList: [],
    myMarkerList: [],
    currentSearchMarkersPage: 1,
    currentMyMarkersPage: 1,
    tempMarker: JSON.parse(JSON.stringify(tempMarkerTemplate)),
    categories: [],
    listenedClips: {},
    selectedMarker: null,
    shouldRefreshMyMarksPage: false,
    shouldRefreshSearchMarksPage: false,
    photosCache: {},
    audiosCache: {}
});

const getters = {
    //
};

const mutations = {
    SET_INIT_GOOGLE_MAP(state, initGoogleMap) {
        state.initGoogleMap = initGoogleMap;
    },

    TOGGLE_MARKER_FAVORITE(state, marker) {
        const index = state.markers.findIndex((m) => m.id == marker.id);
        state.markers[index].is_favorite = !state.markers[index].is_favorite;
    },

    SET_MARKERS(state, markers) {
        state.markers = markers;
    },

    SET_MARKER_SEARCH_LIST(state, { type, markers }) {
        if (type === 'search') {
            state.markerSearchList = markers;
        } else {
            state.myMarkerList = markers;
        }
    },

    SET_MARKER(state, { id, marker }) {
        const index = state.markers.findIndex((m) => m.id == id);
        state.markers.splice(index, 1);
        state.markers.splice(index, 0, marker);
    },

    SET_MARKER_IN_SEARCH_LIST(state, { type, id, marker }) {
        const list = type === 'search' ? state.markerSearchList : state.myMarkerList;
        const index = list.findIndex((m) => m.id == id);
        list.splice(index, 1);
        list.splice(index, 0, marker);
    },

    SET_TEMP_MARKER_LAT_LNG(state, latLng) {
        state.tempMarker.latLng = latLng;
    },

    SET_TEMP_MARKER(state, tempMarker) {
        state.tempMarker = tempMarker;
    },

    SET_CURRENT_LOCATION(state, currentLocation) {
        if (!currentLocation?.coords) {
            return;
        }

        state.currentLocation = {
            accuracy: currentLocation.coords.accuracy,
            lat: currentLocation.coords.latitude,
            lng: currentLocation.coords.longitude
        };
        const storage = new Storage();
        storage.create().then(() => {
            storage.set('prevCoordinate', {
                coords: {
                    latitude: currentLocation.coords.latitude,
                    longitude: currentLocation.coords.longitude
                }
            });
        });
    },

    SET_TEMP_MARMER_ATTRIBUTE(state, { key, value }) {
        let base = state.tempMarker;
        const alist = key.split('.');
        const length = alist.length;
        for (let i = 0; i < length; i ++) {
            if (i != alist.length - 1) {
                base = base[alist[i]];
            } else {
                base[alist[i]] = value;
            }
        }
    },

    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },

    ADD_LISTENED_CLIPS(state, clipHash) {
        state.listenedClips[clipHash] = true;
    },

    ADD_MARKER_TO_SEARCH_LIST(state, { type, marker, unshift = false}) {
        const list = type === 'search' ? state.markerSearchList : state.myMarkerList;
        if (unshift) {
            list.unshift(marker);
        } else {
            list.push(marker);
        }
    },

    SET_CURRENT_SEARCH_MARKERS_PAGE(state, { type, page }) {
        if (type === 'search') {
            state.currentSearchMarkersPage = page;
        } else {
            state.currentMyMarkersPage = page;
        }
    },

    SET_SELECTED_MARKER(state, marker) {
        state.selectedMarker = marker;
    },

    DELETE_MARKER(state, marker) {
        const list = [state.markerSearchList, state.markers, state.myMarkerList];
        for (const markers of list) {
            const index = markers.findIndex((m) => m.id == marker.id);
            if (index > -1) {
                markers.splice(index, 1);
            }
        }
    },

    SET_SCREEN_ORIENTATION_EVENT(state, event) {
        state.screenOrienrtationEvent = event;
    },

    SET_DEVICE_ORIENTATION_EVENT(state, event) {
        state.deviceOrientationEvent = event;
    },

    SET_REFRESH_STATE(state, { type, value }) {
        if (type === 'my') {
            state.shouldRefreshMyMarksPage = value;
        } else {
            state.shouldRefreshSearchMarksPage = value;
        }
    },

    SET_IS_IN_AR_MODE(state, value) {
        state.isInARMode = value;
    },

    ADD_PHOTO_TO_CACHE(state, { photoHash, base64 }) {
        state.photosCache[photoHash] = base64;
    },

    ADD_AUDIO_TO_CACHE(state, { audioHash, base64 }) {
        state.audiosCache[audioHash] = base64;
    },

    REMOVE_PHOTO_FROM_CACHE(state, photoHash) {
        delete state.photosCache[photoHash];
    },

    REMOVE_AUDIO_FROM_CACHE(state, audioHash) {
        delete state.audiosCache[audioHash];
    }
};

const actions = {
    setInitGoogleMap({ commit }, initGoogleMap) {
        commit('SET_INIT_GOOGLE_MAP', initGoogleMap);
    },

    addMarker({ commit, state, rootState }, markerInfo) {
        const markers = JSON.parse(JSON.stringify(state.markers));
        const index = markers.length;
        markers.push({ ...markerInfo, index: index, user: rootState.user.profile });
        commit('SET_MARKERS', markers);
    },

    addMyMarker({ commit }, marker) {
        commit('ADD_MARKER_TO_SEARCH_LIST', { type: 'my', marker: marker });
    },

    updateMarker({ commit }, marker) {
        const id = marker.id;
        commit('SET_MARKER', { id, marker });
    },

    updateMakerInSearchList({ commit }, marker) {
        const id = marker.id;
        commit('SET_MARKER_IN_SEARCH_LIST', { type: 'search', id, marker });
    },

    updateMakerInMyMarkerList({ commit }, marker) {
        const id = marker.id;
        commit('SET_MARKER_IN_SEARCH_LIST', { type: 'my', id, marker });
    },

    setSelectedMarker({ commit }, marker) {
        commit('SET_SELECTED_MARKER', marker);
    },

    setTempMarkerLatLng({ commit }, latLng) {
        commit('SET_TEMP_MARMER_ATTRIBUTE', { key: 'latLng', value: latLng });
    },

    setTempMarkerCategory({ commit }, category) {
        commit('SET_TEMP_MARMER_ATTRIBUTE', { key: 'category', value: category });
    },

    setTempMarkerAttribute({ commit }, { key, value }) {
        commit('SET_TEMP_MARMER_ATTRIBUTE', { key, value });
    },

    setTempMarker({ commit }, marker) {
        commit('SET_TEMP_MARKER', JSON.parse(JSON.stringify(marker)));
    },

    resetTempMarker({ commit }) {
        commit('SET_TEMP_MARKER', JSON.parse(JSON.stringify(tempMarkerTemplate)));
    },

    setCurrentLocation({ commit }, currentLocation) {
        commit('SET_CURRENT_LOCATION', currentLocation);
    },

    fetchCurrentLocation({ commit }) {
        Geolocation.getCurrentPosition().then(coordinates => {
            commit('SET_CURRENT_LOCATION', coordinates);
        });
    },

    async findMarker(_, payload) {
        try {
            const response = await MapApi.findMarker(payload);
            return response.data.clip;
        } catch (e) {
            return null;
        }
    },

    setCurrentMyMarkersPage({ commit }, page) {
        commit('SET_CURRENT_SEARCH_MARKERS_PAGE', { type: 'my', page });
    },

    getClip({ dispatch }, payload) {
        const data = {...payload};
        return MapApi.GetMarker(payload).then(response => {
            const marker = response.data.clip;
            data.plays = marker.plays;
            data.title = marker.title;
            data.description = marker.description;
            data.category = marker.category;
            data.current_user_violations = marker.current_user_violations;
            data.clip_status = marker.clip_status;

            // remove any photo from payload that doesn't exist in marker
            for (const photo of data.photos) {
                const existingIndex = marker.photos.findIndex((p) => p.id === photo.id);
                if (existingIndex === -1) {
                    data.photos.splice(data.photos.indexOf(photo), 1);
                }
            }

            // Add new photos from marker
            for (const photo of marker.photos) {
                const existingIndex = data.photos.findIndex((p) => p.id === photo.id);
                if (existingIndex === -1) {
                    data.photos.push(photo);
                }
            }
            dispatch('updateMarker', data);
        });
    },

    getClips({ dispatch }, params) {
        return dispatch('fetchMarkers', { params });
    },

    clearSearchMarkerList({ commit }) {
        const type = 'search';
        commit('SET_MARKER_SEARCH_LIST', { type, markers: [] });
        commit('SET_CURRENT_SEARCH_MARKERS_PAGE', { type, page: 1 });
    },

    clearMyMarkerList({ commit }) {
        const type = 'my';
        commit('SET_MARKER_SEARCH_LIST', { type, markers: [] });
        commit('SET_CURRENT_SEARCH_MARKERS_PAGE', { type, page: 1 });
    },

    getMyMarkers({ dispatch, rootState }, params) {
        if (!('user_id' in params)) {
            params['user_id'] = rootState['user']['profile']['id'];
        }
        return dispatch('fetchMarkers', { params, type: 'my', pagination: true });
    },

    searchMarkers({ dispatch }, params) {
        return dispatch('fetchMarkers', { params, type: 'search', pagination: true });
    },

    async fetchMarkers({ commit, state }, { params = {}, type = 'search', pagination = false }) {
        params = JSON.parse(JSON.stringify(params));
        const currentLocation = state.currentLocation;
        if (type === 'search') {
            params['lat'] = currentLocation?.lat;
            params['lng'] = currentLocation?.lng;
        }

        return MapApi.GetMarkers(params).then(response => {
            const data = response.data;
            let clips = data.clips;
            if ('data' in clips) {
                clips = clips.data;
            }
            let markers = [];
            for (const clip of clips) {
                const data = {
                    ...clip,
                    latLng: {
                        lat: Number(clip.lat),
                        lng: Number(clip.lng)
                    }
                };
                if (pagination) {
                    commit('ADD_MARKER_TO_SEARCH_LIST', { type: type, marker: data});
                }
                markers.push(data);
            }
            if (!pagination) {
                commit('SET_MARKERS', markers);
            } else if (markers.length > 0) {
                const currentPage = type === 'search' ? state.currentSearchMarkersPage : state.currentMyMarkersPage;
                commit('SET_CURRENT_SEARCH_MARKERS_PAGE', { type: type, page: currentPage + 1 });
            }
            return markers;
        });
    },

    getClipCategories({ commit }) {
        return MapApi.GetClipCategories({}).then(response => {
            const data = response.data;
            const categories = data.categories;
            commit('SET_CATEGORIES', categories);
        });
    },

    getClipAudio({ commit, state }, payload) {
        const hash = payload.hash;
        if (hash in state.audiosCache) {
            return Promise.resolve(state.audiosCache[hash]);
        }
        const params = payload?.params ?? {};
        return MapApi.GetClipAudio(hash, params).then(async (response) => {
            let base64 = Buffer.from(response.data, 'binary').toString('base64');
            commit('ADD_AUDIO_TO_CACHE', { audioHash: hash, base64: base64 });
            return base64;
        });
    },

    async listenClipAudio({ state, commit }, data) {
        const hash = data.hash;
        if (hash in state.listenedClips) {
            return;
        }
        const payload = data?.payload ?? {};
        commit('ADD_LISTENED_CLIPS', hash);
        try {
            const response = await MapApi.ListenClipAudio(hash, payload);
            return response.data.plays;
        } catch (e) {
            return null;
        }
    },

    getClipPhoto({ commit, state }, payload) {
        const clipHash = payload.clipHash;
        const photoHash = payload.photoHash;
        if (photoHash in state.photosCache) {
            return Promise.resolve(state.photosCache[photoHash]);
        }
        const params = payload?.params ?? {};
        return MapApi.GetClipPhoto(clipHash, photoHash, params).then((response) => {
            let base64 = Buffer.from(response.data, 'binary').toString('base64');
            commit('ADD_PHOTO_TO_CACHE', { photoHash, base64: base64 });
            return base64;
        });
    },

    /**
     * Favorite/unfavorite a clip
     *
     * @param {Object} payload
     * 
     */
    async userFavoriteClip(_, payload) {
        const response = await MapApi.UserFavoriteClip(payload);
        // return the updated marker after favoriting/unfavoriting
        return response.data.clip;
    },

    async findMarkerByHash(_, payload) {
        try {
            const response = await MapApi.findMarkerByHash(payload);
            return response.data.clip;
        } catch (e) {
            return null;
        }
    },

    async createClip(_, payload) {
        const photos = JSON.parse(JSON.stringify(payload.photos));
        for (const photo of photos) {
            photo.blob = await fetch(photo.webPath).then(r => r.blob());
        }
        return MapApi.CreateClip(payload, photos);
    },

    async updateClip(_, payload) {
        const photos = JSON.parse(JSON.stringify(payload.photos));
        for (const photo of photos) {
            photo.blob = await fetch(photo.webPath).then(r => r.blob());
        }
        return MapApi.UpdateClip(payload, photos);
    },

    async deleteMarker({ commit }, marker) {
        return MapApi.DeleteMarker(marker.hash).then(() => {
            commit('DELETE_MARKER', marker);
        });
    },

    flagMarker(_, payload) {
        return MapApi.FlagMarker(payload);
    },

    setScreenOrientationEvent({ commit }, payload) {
        commit('SET_SCREEN_ORIENTATION_EVENT', payload);
    },

    setDeviceOrientationEvent({ commit }, payload) {
        commit('SET_DEVICE_ORIENTATION_EVENT', payload);
    },

    setRefreshState({ commit }, payload) {
        commit('SET_REFRESH_STATE', payload);
    },

    setIsInARMode({ commit }, value) {
        commit('SET_IS_IN_AR_MODE', value);
    },

    removePhotoFromCache({ commit }, photoHash) {
        commit('REMOVE_PHOTO_FROM_CACHE', photoHash);
    },

    removeAudioFromCache({ commit }, audioHash) {
        commit('REMOVE_AUDIO_FROM_CACHE', audioHash);
    }
};

export default {
    namespaced: true,
    state: freshState(),
    getters,
    mutations,
    actions
};
